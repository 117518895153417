.alert-modal {
    .MuiDialog-paperWidthSm {
      width: 715px;
      min-height: 400px;
    }
  
    // .MuiDialogContent-root{
    //   align-items: center;
    //     justify-content: center;
    //     display: flex;
    // }
    .MuiDialog-paperScrollPaper {
      background-image: url("../../../../images//audit-bg.jpeg");
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      border: 1px solid #8d8d8d;
      justify-content: space-around;
  
      .MuiDialogTitle-root {
        text-align: center;
        color: #a7a7a7;
        .MuiTypography-h6 {
          font-size: 24px;
        }
      }
  
      // min-width: 715px;
      // height: 300px;
  
      .MuiDialogActions-root {
        justify-content: center;
        .ok-btn {
          background-color: #ff872b;
          color: white;
          width: 130px;
          height: 40px;
          font-size: 20px;
          margin-bottom: -25px;
        }
      }
  
      // .alert-content {
      //   position: relative;
      //   min-width: 70%;
      //   background: white;
      //   height: 200px;
      //   border-radius: 20px;
      //   display: flex;
      //   flex-direction: column;
      //   justify-content: space-around;
      //   align-items: center;
  
      .close-icon {
        position: absolute;
        top: 3px;
        right: 15px;
        cursor: pointer;
        font-weight: bold;
        font-size: 20px;
        color: #a7a7a7;
        transition: all 0.3s ease-in-out;
  
        &:hover {
          // transition: all .3 ease-in-out;
          color: red;
        }
      }
  
      //   .btns-conatiner {
      //     text-align: center;
  
      //     button {
      //       background: #ff872b;
      //       width: 180px;
      //       height: 50px;
      //       color: white;
      //       font-size: 20px;
      //       font-weight: bold;
      //     }
      //   }
      // }
    }
  }
  
  @media (-webkit-device-pixel-ratio: 1.25) {
    .alert-modal {
      zoom: 0.8;
    }
  }
  