.phising-levels {
  .content {
    .level {
      cursor: pointer;
      margin-bottom: 12px;
      div {
        display: flex;
        flex-direction: row;
        align-items: center;
        img {
          width: 35px;
          height: 35px;
        }
      }
      .level_lines {
        width: 100px;
        height: 4px;
        border-radius: 6px;
      }
    }
  }
}
