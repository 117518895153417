// .login-container {
//     margin: 200px;
// }


.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  .welcome-title {
    line-height: 1.56;
    color: #ffffff;
    text-align: center;
    width: 450px;
    margin: auto;

    h3 {
      color: #ffffff;
    }
  }

  .login-section {
    // padding-top: 70px;
    // width: 410px;
    justify-content: center;
    flex-direction: column;
    width: 410px;
    .logo-img {
      height: 150px;
      margin: 0 auto 30px;
      display: block;
    }
    .login-title {
      // font-size: 32px;
      // font-size: 36px;
      line-height: 28px;
      font-size: 24px;
      color: #bcbcbc;
      text-align: center;
      margin-bottom: 33px;
      p {
        margin: 0;
      }
    }

    // .login-title {
    //   p {
    //     margin: 0;
    //   }
    // }

    .input-container {
      display: flex;
      flex-direction: column;
      padding: 0 24px;
      border-left: 1px solid #484848;
      border-right: 1px solid #484848;
      width: 410px;
      width: 100%;
      // min-width: 450px;

      // .form-control {
      //   height: 90px;
      // }

      .MuiTypography-body1 {
        font-size: 15px;
        p{
          margin: 0;
        }
      }

      .Component-root-1.Component-checked-2 {
        padding-right: 14px;
      }

      .MuiCheckbox-root {
        padding: 0 20px 0 0 !important;
      }

      .password-input-section {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        position: relative;
        overflow: hidden;

        .show-hide-pass {
          position: absolute;
          right: -25px;
          top: -28px;

          img {
            width: 85px;
            height: 85px;
            // width: 117px;
            // height: 117px;
          }
        }
      }

      .remember-forget-pass {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        a {
          color: #bcbcbc;
          font-size: 14px;
          transition: all 0.2s;
          p {
            margin: 0;
          }

          &:hover {
            color: #ff872b;
            text-decoration: none;
          }
          // text-decoration: underline;
        }
      }

      .terms-checkbox {
        letter-spacing: normal;
        color: #bcbcbc;
        margin: 0;
      }

      .submit-btn {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        border-radius: 45px;
        background: linear-gradient(to bottom, #464646, #242424);
        // font-size: 19.2px;
        // font-size: 24px;

        letter-spacing: 1px;
        margin-bottom: 30px;
        margin-top: 30px;
        // padding: 13px 30px;
        padding: 8px;

        span {
          color: #fff;
        }

        .MuiButton-label {
          width: fit-content;
          input {
            height: 100%;
          }
        }

        &::after {
          position: relative;
          content: "";
          display: inline-block;
          width: 12px;
          height: 12px;
          border-right: 2px solid #ff6500;
          border-top: 2px solid #ff6500;
          transform: rotate(45deg);
          margin-left: 10px;
        }

        &:hover {
          color: #fff;
          // box-shadow: 0 6px 9px 0 rgb(0 0 0 / 44%);
          /* border: solid 2px rgba(110, 110, 110, 0.13); */
          border: none;
          background-color: #ff6500;
          background-image: linear-gradient(to bottom, #ff4600, #802300);
          // span {
          //   color: #ff872b;
          // }
        }

        .right-arrow {
          color: #ff6500;
          font-size: 30px;
        }
      }

      .loading-btn {
        box-shadow: 0 6px 9px 0 rgba(0, 0, 0, 0.44);
        border: solid 1px rgba(110, 110, 110, 0.13);
        background-image: linear-gradient(to bottom, #ec7f01, #cf3d00);

        .account-btn-container {
          p {
            margin: 0;
          }
        }
      }

      span {
        color: #d1d1d1;
      }

      .input-error {
        border: solid 4px #cf0000;
      }

      input {
        // padding: 24px 20px;
        // height: 55px;
        height: 50px;
        border-radius: 10px;
        font-size: 13px;
        // font-size: 24px;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.3);
        background-color: #cecece;
        width: 100%;
        padding-left: 18px;
      }
      .email {
        margin-bottom: 15px;
        width: unset;
      }
    }

    // .error-section {
    //   .error {
    //     margin: 28.5px 24.5px 0;
    //     // padding: 8.3px 86.5px 8.3px 19.5px;
    //     padding: 8px 50px 8px 12px;
    //     border-radius: 10px;
    //     background-color: #cf0000;
    //     color: #ffffff;
    //     display: flex;
    //     flex-direction: row;
    //     justify-content: space-between;

    //     span {
    //       p {
    //         margin: 0;
    //       }
    //     }

    //     span:first-of-type {
    //       border-right: 1px solid white;
    //       padding-right: 20px;
    //       display: flex;
    //       flex-direction: row;
    //       align-items: center;
    //       justify-content: center;
    //     }

    //     .warning-icon {
    //       margin-right: 12px;
    //       font-size: 20px;
    //     }
    //   }
    // }
  }
}

@media (max-width: 425px) {
  .login-container {
    .welcome-title {
      width: 90%;
      margin-top: 30px;

      h3 {
        font-size: 20px;
      }
    }

    .login-section {
      padding-top: 30px;
      width: 100%;

      .input-container {
        min-width: unset;

        input {
          padding: 25px 20px;
          margin-bottom: 20px;
        }

        span,
        a {
          font-size: 10px;
          padding: 0 0px 0 4px;
        }

        .password-input-section {
          .show-hide-pass {
            top: -30px;

            img {
              width: 90px;
              height: 90px;
            }
          }
        }

        .submit-btn {
          padding: 10px 0;
          margin: 10px 0;
          font-size: 16px;
        }
        // .remember-forget-pass {
        //   flex-direction: column;
        // }
      }
    }
  }
}
.input-error {
  border: solid 4px #cf0000 !important;
}

.error-section {
  .error {
    margin: 28.5px 24.5px 0;
    // padding: 8.3px 86.5px 8.3px 19.5px;
    padding: 8px 12px;
    border-radius: 10px;
    background-color: #cf0000;
    color: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    text-align: center;

    .text-err {
      padding-left: 10px;
    }
    span {
      p {
        margin: 0;
      }
    }

    span:first-of-type {
      border-right: 1px solid white;
      padding-right: 20px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }

    .warning-icon {
      margin-right: 12px;
      font-size: 20px;
    }
  }
}


@media (-webkit-device-pixel-ratio: 1.25) {
  .login-container {
    zoom: 0.8;
  }
}

@media (-webkit-min-device-pixel-ratio: 2.25) {
  .login-container {
    zoom: 0.8;
  }
}

