// * {
//   margin: 0;
//   padding: 0;
// }

body {
  background-image: url("../src/images/audit-bg.jpeg");

  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow: auto;
  margin: 0;

  &::-webkit-scrollbar {
    color: gray;
    width: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background: gray;
    border-radius: 12px;
    width: 10px;
    border: 5px;
  }
  &::-webkit-scrollbar-track {
    background-color: #3b3b3b;
  }
}

*{
  font-family: "Segoe UI Regular", sans-serif !important;
}
