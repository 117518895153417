// Next Button
.custom-next-btn {
    text-align: center;
    button {
      font-weight: bold;
      color: white;
      // height: 54px;
      // height: 74px;
      // height: 60px;
      // line-height: 54px;
      // width: 180px;
      width: 100%;
    //   width: 200px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      border-radius: 45px;
      // background: linear-gradient(to bottom, #464646, #242424);
      background-image: linear-gradient(to bottom, #ff9232, #804919);

      // font-size: 19.2px;
      // font-size: 20px;
      // font-size: 18px;
      letter-spacing: 1px;
      border: none;
      padding: 12px;
  
      span p {
        margin: 0 !important;
        font-size: unset !important;
      }

  
      &:hover {
        // color: #ff872b;
        background-image: linear-gradient(to bottom, #fc8217, #81440f);      }
  
      // .MuiButton-label::after {
      //   position: relative;
      //   content: "";
      //   display: inline-block;
      //   width: 12px;
      //   height: 12px;
      //   border-right: 2px solid #ff6500;
      //   border-top: 2px solid #ff6500;
      //   transform: rotate(45deg);
      //   margin-left: 10px;
      // }
    }
  }
  