.container {
    position: relative;
    height: auto;
  //   width: 50%;
    img {
      object-fit: cover;
      border-radius: 50%;
    }
    .xicon {
      position: absolute;
      position: absolute;
      right: 48px;
      bottom: 35px;
      cursor: pointer;
    }
  }
  