@import "../../utilities/cssColors/style.scss";

.container__ {
  width: 95%;
  // min-height: 90vh;
  display: flex;
  flex-direction: column;
  margin: auto;
  .line-chart-container {
    width: 98%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    .content__ {
      display: flex;
      flex-direction: column;
      position: relative;
      width: 100%;
  
      .top-chart-content {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        //   width: 80%;
        //   margin: auto;
        width: 90%;
        margin-left: 75px;
  
        .right-left {
          width: 25%;
        }
  
        .title {
          width: 50%;
          h1 {
            text-align: center;
            // margin: 0;
            color: white;
            width: 100%;
            p {
              margin: 0;
            }
          }
        }
      }
  
      .linechart__ {
        width: 100%;
        
      }
    }
    .attack_section {
      width: 40%;
      background-color: red;
      height: 100%;
    }
  }
  .notest__container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: x-large;
    color: white;
  }
  .top__ {
    width: 100%;
    // height: 50%;
    display: flex;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 60px;
    .page__content__ {
      width: 90%;
      display: flex;
      justify-content: space-around;
     
      align-items: flex-start;
    }
  }
}

// @media (-webkit-min-device-pixel-ratio: 2.25) {
//   .container__ {
//     zoom: 0.8;
//     // .down__ {
//     //   zoom: 1;
//     // }
//   }
// }

@media (-webkit-device-pixel-ratio: 1.25) {
  .container__ {
    height: unset;
    .page__content__ {
      zoom: 0.8;
    }
    // .top__ {
    //   margin-bottom: 30px;
    // }
  }
}

@media (-webkit-min-device-pixel-ratio: 2.25) {
  .container__ {
    height: unset;
    .page__content__ {
      zoom: 0.8;
    }
    // .top__ {
    //   margin-bottom: 30px;
    // }
  }
}

// @media (-webkit-min-device-pixel-ratio: 1.25) {
//   .container__  {
//     zoom: 0.8;
//   }
// }

.recharts-wrapper .recharts-cartesian-grid-horizontal line:first-child,
.recharts-wrapper .recharts-cartesian-grid-horizontal line:last-child,
.recharts-wrapper .recharts-cartesian-grid-vertical line:first-child,
.recharts-wrapper .recharts-cartesian-grid-vertical line:last-child{
      stroke-opacity: 0;
}