.change-password-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 600px;
    min-height: 600px;
    margin: auto;
  
    form {
      width: 500px;
      color: white;
  
      .title-container {
        width: 68%;
        margin: 0 0 40px auto;
  
        .title {
          // width: 78%;
          // width: 68%;
          // margin: 0 0 40px auto;
          font-size: 22px;
        }
      }
  
      input {
        height: 45px;
      }
    }
  
    .input-content {
      display: flex;
      align-items: center;
      p {
        width: 30%;
        margin-right: 12px;
      }
      input {
        width: 70%;
        margin-bottom: 20px;
      }
    }
  
    .validate-btn {
      //   color: #fff;
      //   background-color: #ff6500;
      //   border: none;
      margin-left: 159px;
      font-weight: normal;
      border: none;
    }
    .show-success {
      background: #009688;
      padding: 12px;
      text-align: center;
      margin-top: 12px;
      border-radius: 12px;
      p {
        margin-bottom: 0;
      }
    }
  }
  
  @media (-webkit-device-pixel-ratio: 1.25) {
    .change-password-container {
      zoom: 0.8;
    }
  }
  