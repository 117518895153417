@import "../../utilities/cssColors/style.scss";

.drondown_container {
  // height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  max-width: 150px;
  // position: absolute;
  // left: 77px;
  // top: 5%;
  .title_container {
    width: 95%;
    text-align: left;
    height: 40px;
  }
  .header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    cursor: pointer;
    border-radius: 10px;
    box-shadow: $grayShadow;
    background: $grayColor;
    color: white;
    height: 55px;

    p {
      width: 75%;
      margin-left: auto;
    }

    .icon_container {
      display: flex;
      width: 20%;
      justify-content: space-around;
    }
  }
  .droplist {
    background-color: white;
    width: 100%;
    color: white;
    overflow-y: scroll;
    margin-top: 2px;
    border-radius: 10px;
    position: absolute;
    top: 55px;
    padding: 6px 0;
    box-shadow: $grayShadow;
    background: $grayColor;
    z-index: 3;
    
    p {
      // width: 90%;
      margin: auto;
      // padding: 5px;
      padding: 6px 12px;

      &:hover {
        background-color: rgb(97, 125, 248);
        cursor: pointer;
        color: white;
      }
    }
  }
}
