// Accounts Button
.account-btn-container {
    button {
      display: flex;
      flex-direction: row;
      justify-content: center;
      width: 100%;
      align-items: center;
      border-radius: 45px;
      background: linear-gradient(to bottom, #464646, #242424);
      font-size: 19.2px;
      // font-size: 20px;
      letter-spacing: 1px;
      margin-bottom: 30px;
      margin-top: 30px;
      padding: 8px;
      height: 55px;
      // height: 90px;
  
      span {
        color: #fff !important;
        margin: 0;
        p {
          margin: 0;
        }
      }
  
      .MuiButton-label {
        width: fit-content;
        input {
          height: 100%;
        }
      }
  
      &::after {
        position: relative;
        content: "";
        display: inline-block;
        width: 12px;
        height: 12px;
        border-right: 2px solid #ff6500;
        border-top: 2px solid #ff6500;
        transform: rotate(45deg);
        margin-left: 10px;
      }
  
      &:hover {
        color: #fff;
        border: none;
        background-color: #ff6500;
        background-image: linear-gradient(to bottom, #ff4600, #802300);
      }
    }
  }
  