.reset-pass-container {
  .reset-pass-section {
    display: flex;
    //   justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: 600px;
    width: 450px;
    margin: auto;

    .reset-title {
      font-size: 32px;
      // color: #ff872b;
      color: #d1d1d1;
      text-align: center;
      margin-bottom: 30px;
      p {
        margin: 0;
      }
    }

    .input-container {
      display: flex;
      flex-direction: column;
      padding: 12px 20px;
      border-right: solid 1px #323232;
      border-left: solid 1px #323232;
      // width: 500px;
      width: 410px;

      .submit-btn {
        width: 100%;
        margin-bottom: 20px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        border-radius: 45px;
        background: linear-gradient(to bottom, #464646, #242424);
        font-family: Roboto;
        font-size: 20px;
        font-weight: bold;
        color: #d1d1d1;
        margin-bottom: 20px;
        margin-top: 30px;
        // padding: 8px;
        padding: 13px 30px;
        height: 55px;

        &:hover {
          color: #ff872b;
        }
      }

      input {
        // padding: 25px 20px;
        height: 50px;
        border-radius: 10px;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.3);
        background-color: #cecece;
        width: 100%;

        &:first-of-type {
          margin-bottom: 32px;
        }
      }

      a {
        padding-left: 12px;
        color: #bcbcbc;
        text-decoration: underline;
      }
    }

    .error-section {
      width: 100%;
      .error {
        margin: 28.5px 24.5px 0;
        padding: 8.3px 86.5px 8.3px 19.5px;
        border-radius: 10px;
        background-color: #cf0000;
        color: #ffffff;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        span:first-of-type {
          border-right: 1px solid white;
          padding-right: 20px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
        }

        .warning-icon {
          margin-right: 12px;
          font-size: 20px;
        }
      }
    }
  }
}

@media (max-width: 425px) {
  .reset-pass-container {
    .reset-pass-section {
      width: 100%;

      p {
        font-size: 25px;
        margin-bottom: 30px;
      }
      .input-container {
        width: 100%;

        input {
          padding: 25px 12px;
        }
        input:first-of-type {
          margin-bottom: 20px;
        }
        .submit-btn {
          margin-bottom: 0;
          margin-top: 25px;
          padding: 10px 40px 10px 60px;
          font-size: unset;
        }
      }

      .error-section {
        .error {
          margin: auto;
          padding: 8px 12px;
          width: 90%;

          span:first-of-type {
            padding-right: 10px;
          }
        }
      }
    }
  }
}

@media (-webkit-device-pixel-ratio: 1.25) {
  .forgot-pass-container {
    zoom: 0.8;
  }
}

@media (-webkit-min-device-pixel-ratio: 2.25) {
  .reset-pass-container {
    zoom: 0.8;
  }
}

