.speed-meeter-chart {
  // width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 50%;

  .risk-number-content {
    p {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0;
      color: white;

      span {
        color: white;
        font-size: 20px;
        margin-right: 8px;
      }
    }
  }

  h1 {
    text-align: center;
    margin-top: 0;
    color: white;
  }
  .sepedchart__ {
    width: 100%;

    height: 90%;
  }
}

// @media (-webkit-min-device-pixel-ratio: 2.25) {
//   .speed-meeter-chart {
//     zoom: 0.8;
//   }
// }
