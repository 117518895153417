@import "../../../utilities/cssColors/style.scss";

.phising-info {
  // height: 100%;
  height: 350px;

  width: 25%;

  .content {
    // width: 60%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    color: white;
    background: $grayColor;
    padding: 0 20px;
    border-radius: 10px;
    box-shadow: $grayShadow;

    p {
      margin: 0;
      // font-weight: bold;
      font-size: 18px;
    }

    .title {
      width: 100%;
      text-align: center;
      margin: 12px 0;

      h2 {
        width: 100%;
        margin: 6px 0;
      }
    }

    .info {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-bottom: 25px;
      p {
        div {
          margin: 0 !important;
        }
      }
    }
  }
}


// @media (-webkit-min-device-pixel-ratio: 2.25) {
//   .phising-info {
//     zoom: 0.8;
//   }
// }

